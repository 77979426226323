<template>
    <div class="flex-1 lg:mr-2">
        <div class="mb-2 text-right" :class="{ 'pt-6': !materialGuideLink }">
            <a
                v-if="materialGuideLink"
                target="_blank"
                :href="materialGuideLink"
            >
                Material Guide<Icon
                    class="ml-1 inline-block text-sm"
                    name="arrow-up-right-from-square-solid"
                />
            </a>
        </div>
        <Dropdown
            v-model="currentMaterial"
            :choices="
                getCurrentProduct()?.materials.map(
                    (material: TemplateGalleryProductMaterialType) => {
                        return {
                            value: material.name,
                            label: material.name,
                        };
                    },
                ) ?? []
            "
            placeholder="Select a Material"
            @change="$emit('selectMaterial', getFullMaterialObject($event))"
        />
    </div>
    <Modal ref="materialModalRef" :allow-close="true">
        <template #title>Material Details</template>
        <Dropdown
            v-if="currentProduct"
            v-model="currentMaterial"
            :choices="
                getCurrentProduct()?.materials.map(
                    (material: TemplateGalleryProductMaterialType) => {
                        return {
                            value: material.name,
                            label: material.name,
                        };
                    },
                ) ?? []
            "
            placeholder="Select a Material"
            @change="$emit('selectMaterial', getFullMaterialObject($event))"
        />
        <div v-if="currentMaterial" class="mt-4">
            {{ currentMaterial.description }}
        </div>
        <template #actions>
            <button class="btn-secondary" @click="materialModalRef?.close()">
                Close
            </button>
        </template>
    </Modal>
</template>

<script setup lang="ts">
import TemplateGalleryProductType from '~/types/TemplateGalleryProductType';
import TemplateGalleryProductMaterialType from '~/types/TemplateGalleryProductMaterialType';
import Modal from '~/components/Modal.vue';
import { slugify } from '~/utils/helpers';

const materialModalRef = ref<InstanceType<typeof Modal> | null>(null);
const emit = defineEmits(['selectMaterial']);

const props = defineProps<{
    currentProduct: string;
    products: TemplateGalleryProductType[];
    materialGuideLink?: string;
}>();

const state = reactive({
    currentMaterial: null,
});

const { currentMaterial } = toRefs(state);

const getCurrentProduct = () => {
    for (const product of props.products) {
        if (slugify(product.name) == props.currentProduct) {
            return product;
        }
    }
    return null;
};

const getFullMaterialObject = (materialName: string) => {
    for (const material of getCurrentProduct()?.materials) {
        if (material.name == materialName) {
            return material;
        }
    }

    return null;
};

const thisProduct = getCurrentProduct();
if (thisProduct?.materials.length === 1) {
    currentMaterial.value = thisProduct.materials[0].name;
    emit('selectMaterial', getFullMaterialObject(currentMaterial.value));
}
</script>
