<template>
    <div class="flex-1 lg:ml-2">
        <div class="mb-2 text-right">
            <button
                class="btn-link text-brew underline"
                :class="currentMaterial == null ? 'opacity-50' : ''"
                :disabled="currentMaterial == null"
                @click="dimensionModalRef?.open()"
            >
                Dimension Details
            </button>
        </div>
        <Dropdown
            v-model="currentSize"
            :choices="
                currentMaterial?.sizes.map(
                    (size: TemplateGalleryProductMaterialSizeType) => {
                        return {
                            value: size.name,
                            label: size.name,
                        };
                    },
                ) ?? []
            "
            placeholder="Select a Size"
            :disabled="currentMaterial == null"
            @change="$emit('selectSize', getFullSizeObject($event))"
        />
    </div>
    <Modal ref="dimensionModalRef" :allow-close="true">
        <template #title>Dimension Details</template>
        <Dropdown
            v-if="currentMaterial"
            v-model="currentSize"
            :choices="
                currentMaterial?.sizes.map(
                    (size: TemplateGalleryProductMaterialSizeType) => {
                        return { value: size.name, label: size.name };
                    },
                ) ?? []
            "
            placeholder="Select a Size"
            :disabled="currentMaterial == null"
            @change="$emit('selectSize', getFullSizeObject($event))"
        />
        <table v-if="currentSize" class="mt-4 w-full">
            <tr
                v-for="dimension in currentSize.dimensions"
                :key="dimension.dimension"
            >
                <td class="p-4 font-bold"><br />{{ dimension.dimension }}</td>
                <td class="p-4">
                    {{ dimension.subdimension1?.toUpperCase() }}<br />
                    {{ dimension.measurement1 }}
                </td>
                <td class="p-4">
                    {{ dimension.subdimension2?.toUpperCase() }}<br />
                    {{ dimension.measurement2 }}
                </td>
            </tr>
        </table>
        <template #actions>
            <button class="btn-secondary" @click="dimensionModalRef?.close()">
                Close
            </button>
        </template>
    </Modal>
</template>

<script setup lang="ts">
import TemplateGalleryProductType from '~/types/TemplateGalleryProductType';
import TemplateGalleryProductMaterialType from '~/types/TemplateGalleryProductMaterialType';
import TemplateGalleryProductMaterialSizeType from '~/types/TemplateGalleryProductMaterialSizeType';
import Modal from '~/components/Modal.vue';

const dimensionModalRef = ref<InstanceType<typeof Modal> | null>(null);
const emit = defineEmits(['selectSize']);

const props = defineProps<{
    currentProduct: string;
    currentMaterial: TemplateGalleryProductMaterialType | null;
    products: TemplateGalleryProductType[];
}>();

const state = reactive({
    currentSize: null,
});

const { currentSize } = toRefs(state);

watch(
    () => props.currentMaterial,
    async () => {
        if (props.currentMaterial?.sizes.length === 1) {
            currentSize.value = props.currentMaterial.sizes[0].name;
            emit('selectSize', props.currentMaterial.sizes[0]);
        } else {
            currentSize.value = null;
        }
    },
);

const getFullSizeObject = (sizeName: string) => {
    for (const size of props.currentMaterial?.sizes) {
        if (size.name == sizeName) {
            return size;
        }
    }

    return null;
};
</script>
