<template>
    <TemplateGallery v-editable="blok" :data="templateGalleryContent" />
</template>

<script setup lang="ts">
import TemplateGalleryType from '@/types/TemplateGalleryType';
import TemplateGalleryProductType from '@/types/TemplateGalleryProductType';
import TemplateGalleryProductMaterialType from '@/types/TemplateGalleryProductMaterialType';
import TemplateGalleryProductMaterialSizeType from '@/types/TemplateGalleryProductMaterialSizeType';
import TemplateGalleryProductMaterialSizeDimensionType from '@/types/TemplateGalleryProductMaterialSizeDimensionType';
import ImageType from '@/types/ImageType';
import TemplateGallery from '~/components/page-building/template-gallery/TemplateGallery.vue';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const templateGalleryContent = computed<TemplateGalleryType>(() => {
    return {
        title: props.blok.title,
        description: richTextToHtml(props.blok.description) as string,
        backgroundColor: props.blok.background_color,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        bottomAngle: props.blok.bottom_angle,
        materialGuide: props.blok.material_guide?.filename as string,
    };
});
</script>
